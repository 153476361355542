import { Container, Grid, Typography } from "@mui/material";
import { TournamentsTable } from "./TournamentsTable";

export const Tournaments = () => {
  return (
    <Container sx={{ padding: 2 }}>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h3">Tournaments</Typography>
        </Grid>
        <Grid item xs={12}>
          <TournamentsTable />
        </Grid>
      </Grid>
    </Container>
  );
};
