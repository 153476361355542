import { Route, Routes } from "react-router-dom";
import { Tournaments } from "../features/tournaments/Tournaments";

export const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Tournaments />} />
      <Route path="/tournaments" element={<Tournaments />} />
    </Routes>
  );
};
