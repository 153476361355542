import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { functions } from "@nikolausturnier/shared/src/firebase";
import { ThunkApi } from "../../app/hooks";
import { RootState } from "../../app/store";

import { Tournament } from "../../types/Tournament.type";
import { httpsCallable } from "firebase/functions";
import { selectUsers } from "../users/users.feature";

const tournamentsAdapter = createEntityAdapter<Tournament>({});

const initialState = tournamentsAdapter.getInitialState({
  status: "idle",
});

export const getTournaments = createAsyncThunk<Tournament[], void, ThunkApi>(
  "tournaments/getTournaments",
  async () => {
    const getTournaments = httpsCallable(functions, "getTournaments");
    const result = await getTournaments();
    return result.data as Tournament[];
  },
);

export const tournamentsSlice = createSlice({
  name: "tournaments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTournaments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTournaments.fulfilled, (state, action) => {
        tournamentsAdapter.addMany(state, action.payload);
        state.status = "idle";
      })
      .addCase(getTournaments.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  selectAll: selectTournaments,
  selectById: selectTournamentById,
} = tournamentsAdapter.getSelectors((state: RootState) => state.tournaments);

export const selectTournamentsWithUsers = createSelector(
  selectTournaments,
  selectUsers,
  (tournaments, users) => {
    return tournaments
      .map((tournament) => {
        return {
          ...tournament,
          user: users.find((user) => user.id === tournament.userId),
        };
      })
      .sort((a, b) => b.date.localeCompare(a.date));
  },
);

export default tournamentsSlice.reducer;
