import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "./store";
import { getTournaments } from "../features/tournaments/tournaments.feature";
import { getUsers } from "../features/users/users.feature";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type ThunkApi = { state: RootState };

export const useTournamentStore = () => {
  const dispatch = useAppDispatch();
  dispatch(getTournaments())
    .then(() => dispatch(getUsers()))
    .finally(() => {
      console.log("Successfully fetched tournaments and users");
    });
};
