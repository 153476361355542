import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { EmptyState } from "../../components/EmptyState";
import { Label } from "../../types/Label.type";
import { useAppSelector } from "../../app/hooks";
import { selectTournamentsWithUsers } from "./tournaments.feature";
import { getDateString } from "../../app/dates";
import { DateChip } from "../../components/chips/DateChip";
import { selectIsLoading } from "../status.feature";
import { LoadingSkeleton } from "../../components/LoadingSkeleton";

export const TournamentsTable = () => {
  const isLoading = useAppSelector(selectIsLoading);
  const tournaments = useAppSelector(selectTournamentsWithUsers);

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (tournaments.length > 0) {
    return (
      <TableContainer>
        <Table size="small">
          <caption>{tournaments.length} tournament(s) found</caption>
          <TableHead>
            <TableRow
              sx={{
                "& th": { fontWeight: 600 },
              }}
            >
              <TableCell>Status</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tournaments.map((tournament) => (
              <TableRow
                key={tournament.id}
                sx={{
                  "& td": { lineHeight: 2 },
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell>
                  <DateChip date={tournament.date} />
                </TableCell>
                <TableCell>{tournament.name}</TableCell>
                <TableCell>{getDateString(tournament.date)}</TableCell>
                <TableCell>{tournament?.user?.username}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <EmptyState
        icon={<EmojiEventsIcon fontSize="large" />}
        label={Label.Tournament}
      />
    );
  }
};
