import { configureStore } from "@reduxjs/toolkit";
import tournamentsReducer from "../features/tournaments/tournaments.feature";
import usersReducer from "../features/users/users.feature";

export const store = configureStore({
  reducer: {
    tournaments: tournamentsReducer,
    users: usersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
