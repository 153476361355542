import { Grid, Skeleton, Stack } from "@mui/material";

export const LoadingSkeleton = () => {
  return (
    <Stack spacing={1}>
      <SkeletonRow height={40} />
      <SkeletonRow height={20} />
      <SkeletonRow height={20} />
      <SkeletonRow height={20} />
    </Stack>
  );
};

type SkeletonRowProps = {
  height: number;
};

const SkeletonRow = ({ height }: SkeletonRowProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" height={height} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" height={height} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton variant="rectangular" height={height} />
      </Grid>
    </Grid>
  );
};
