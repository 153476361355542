import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { functions } from "@nikolausturnier/shared/src/firebase";
import { ThunkApi } from "../../app/hooks";
import { RootState } from "../../app/store";

import { httpsCallable } from "firebase/functions";
import { User } from "../../types/User.type";

const usersAdapter = createEntityAdapter<User>();

const initialState = usersAdapter.getInitialState({
  status: "idle",
});

export const getUsers = createAsyncThunk<User[], void, ThunkApi>(
  "users/getUsers",
  async () => {
    const getUsers = httpsCallable(functions, "getUsers");
    const result = await getUsers();
    return result.data as User[];
  },
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        usersAdapter.addMany(state, action.payload);
        state.status = "idle";
      })
      .addCase(getUsers.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { selectAll: selectUsers, selectById: selectUserById } =
  usersAdapter.getSelectors((state: RootState) => state.users);

export default usersSlice.reducer;
